exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-app-dashboard-tsx": () => import("./../../../src/pages/app/dashboard.tsx" /* webpackChunkName: "component---src-pages-app-dashboard-tsx" */),
  "component---src-pages-app-forgot-password-tsx": () => import("./../../../src/pages/app/forgotPassword.tsx" /* webpackChunkName: "component---src-pages-app-forgot-password-tsx" */),
  "component---src-pages-app-login-tsx": () => import("./../../../src/pages/app/login.tsx" /* webpackChunkName: "component---src-pages-app-login-tsx" */),
  "component---src-pages-app-place-order-tsx": () => import("./../../../src/pages/app/place-order.tsx" /* webpackChunkName: "component---src-pages-app-place-order-tsx" */),
  "component---src-pages-app-profile-tsx": () => import("./../../../src/pages/app/profile.tsx" /* webpackChunkName: "component---src-pages-app-profile-tsx" */),
  "component---src-pages-app-register-tsx": () => import("./../../../src/pages/app/register.tsx" /* webpackChunkName: "component---src-pages-app-register-tsx" */),
  "component---src-pages-app-simulator-tsx": () => import("./../../../src/pages/app/simulator.tsx" /* webpackChunkName: "component---src-pages-app-simulator-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-enquiry-tsx": () => import("./../../../src/pages/enquiry.tsx" /* webpackChunkName: "component---src-pages-enquiry-tsx" */),
  "component---src-pages-equipment-categories-tsx": () => import("./../../../src/pages/equipment-categories.tsx" /* webpackChunkName: "component---src-pages-equipment-categories-tsx" */),
  "component---src-pages-equipment-details-tsx": () => import("./../../../src/pages/equipment-details.tsx" /* webpackChunkName: "component---src-pages-equipment-details-tsx" */),
  "component---src-pages-equipments-tsx": () => import("./../../../src/pages/equipments.tsx" /* webpackChunkName: "component---src-pages-equipments-tsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */)
}

